const environment = {
	api: {
		url: "https://api-demo.ampmemberships.com",
	},
	localhost: {
		tenantKey: "",
	},
	aws: {
		url: "https://demo-ampmemberships-com.s3.us-west-1.amazonaws.com/public",
	},
	sentry: {
		dsn: "https://0c3e52eb1c06457f8f7a231343f7d3f4@o641321.ingest.sentry.io/6761335",
	},
	google: {
		maps_api: "AIzaSyBWo_SGwBfdpeD6l8AsYz_iQPyB3qmu7t8",
	}
};

export default environment;